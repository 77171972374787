import { render, staticRenderFns } from "./addCustomRecord.vue?vue&type=template&id=3be04584&scoped=true&"
import script from "./addCustomRecord.vue?vue&type=script&lang=js&"
export * from "./addCustomRecord.vue?vue&type=script&lang=js&"
import style0 from "./addCustomRecord.vue?vue&type=style&index=0&id=3be04584&prod&lang=scss&"
import style1 from "./addCustomRecord.vue?vue&type=style&index=1&id=3be04584&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3be04584",
  null
  
)

export default component.exports