<template>
  <div>
    <vs-button
      :color="buttonCollor"
      :type="buttonType"
      :class="buttonClass"
      @click="poUpActive = true"
    >
      {{ buttonText }}
    </vs-button>

    <div
      v-if="poUpActive"
      class="h-screen w-full bg-opacity-25 bg-black fixed top-0 left-0 z-10"
    >
      <div
        id="popUpMask"
        @click="onCancel"
        class="h-full w-full flex items-center justify-center"
      >
        <div class="max-w-sm vs-popup px-5 py-3 m-4" v-on:click.stop>
          <div id="popUpHeader" class="vs-popup--header">
            <di class="vs-popup--title">
              <h3>{{ popUpTitle }}</h3>
            </di>
          </div>
          <div class="vs-popup--content">
            <div class="py-5">
              <p>
                This record will be struck out but still visible for reference.
              </p>
            </div>
            <div id="popUp footer" class="text-right w-full">
              <vs-button
                @click="onCancel"
                color="primary"
                type="border"
                class="mr-2 cancel-btn"
              >
                {{ cancelText }}
              </vs-button>
              <vs-button color="primary" type="filled" @click="onOk">
                {{ confirmText }}
              </vs-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    buttonText: {
      type: String,
      default: "Delete",
    },
    buttonType: {
      type: String,
      default: "flat",
    },
    buttonCollor: {
      type: String,
      default: "danger",
    },
    buttonClass: {
      type: String,
      default: "w-full radius",
    },
    popUpTitle: {
      type: String,
      default: "Delete Record?",
    },
    popUpMessage: {
      type: String,
      default: "Are you sure you want to delete record?",
    },
    confirmText: {
      type: String,
      default: "Confirm",
    },
    cancelText: {
      type: String,
      default: "Cancel",
    },
  },
  data: () => ({
    poUpActive: false,
  }),
  methods: {
    onOk() {
      this.$emit("onConfirm");
    },
    onCancel() {
      this.poUpActive = false;
      this.$emit("onCancel");
    },
  },
};
</script>

<style>
  .vs-popup {
      -webkit-transition: all .2s;
      transition: all .2s;
      z-index: 100;
      border-radius: 6px;
      -webkit-box-shadow: 0 5px 20px 0 rgba(0, 0, 0, .1);
      box-shadow: 0 5px 20px 0 rgba(0, 0, 0, .1);
      background: #fff;
      -webkit-animation: rebound .3s;
      animation: rebound .3s;
  }

  .vs-popup--content {
    padding: 0;
    font-size: 1rem;
    width: auto;
  }

  .cancel-btn {
    padding: 8px 12px;
  }
</style>
